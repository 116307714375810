import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import CommentForm from './CommentForm';
import ThreadCard from './ThreadCard';
import CommentCard from './CommentCard';
import './ThreadDetail.css';
import { Guide2 } from '../styles/icons';

const ThreadDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [thread, setThread] = useState(null);
  const [comments, setComments] = useState([]);
  const [replyTo, setReplyTo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newCommentId, setNewCommentId] = useState(null);

  useEffect(() => {
    const fetchThreadDetails = async () => {
      setLoading(true);
      try {
        let accessId = JSON.parse(localStorage.getItem('accessId')) || {};
        if (!accessId || Object.keys(accessId).length === 0){
          const ses_response = await axios.get(`${process.env.REACT_APP_API_URL}/getSessionId`);
          localStorage.setItem('accessId', JSON.stringify(ses_response.data.sessionId));
          accessId = JSON.parse(localStorage.getItem('accessId')) || {};
        }
  
        if (id) {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/threads/${id}`, {
            params: { sessionId: accessId }, // sessionIdをクエリパラメータとして追加
            withCredentials: true,
          });
          setThread(response.data);
          setComments(response.data.comments);
        } else {
          console.error('No thread ID found.');
        }
      } catch (error) {
        console.error('Failed to fetch thread details:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchThreadDetails();
  }, [id]);

  const threadLike = async () => {
    try {
      const accessId = JSON.parse(localStorage.getItem('accessId')) || {};
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/threadLikes`,
        { THREAD_ID: thread.THREAD_ID, sessionId: accessId },
        { withCredentials: true }
      );
      if (response.data.action === 'liked') {
        setThread({ ...thread, likes: thread.likes + 1 });
      } else if (response.data.action === 'unliked') {
        setThread({ ...thread, likes: thread.likes - 1 });
      }
    } catch (error) {
      console.error('Failed to update like:', error);
    }
  };

  const commentLike = async (commentId) => {
    try {
      const accessId = JSON.parse(localStorage.getItem('accessId')) || {};
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/commentLikes`,
        { THREAD_ID: thread.THREAD_ID, COMMENT_ID: commentId, sessionId: accessId },
        { withCredentials: true }
      );
      if (response.data.action === 'liked') {
        setComments(comments.map(comment =>
          comment.COMMENT_ID === commentId ? { ...comment, likes: (comment.likes || 0) + 1, liked: true } : comment
        ));
      } else if (response.data.action === 'unliked') {
        setComments(comments.map(comment =>
          comment.COMMENT_ID === commentId ? { ...comment, likes: (comment.likes || 0) - 1, liked: false } : comment
        ));
      }
    } catch (error) {
      console.error('Failed to update like:', error);
    }
  };

  useEffect(() => {
    if (newCommentId !== null) {
      scrollToElement(newCommentId);
    }
    setNewCommentId(null);
  }, [newCommentId]);

  const handleCommentSubmit = async (TEXT, NICKNAME, PHOTO_NAME) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/comments`,
        { THREAD_ID: thread.THREAD_ID, TEXT, NICKNAME, PHOTO_NAME, UPPER_COMMENT_ID: replyTo?.COMMENT_ID || null },
        { withCredentials: true }
      );
      setComments(comments => [...comments, response.data]);
      setReplyTo(null);
      setNewCommentId(response.data.COMMENT_ID);
    } catch (error) {
      console.error('Failed to add comment:', error);
    }
  };

  const scrollToElement = (elementID) => {
    const element = document.getElementById(elementID);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.warn(`Element with ID ${elementID} not found.`);
    }
  };

  const handleReply = (comment) => {
    setReplyTo(comment);
    // console.log('Replying to comment:', comment); // デバッグ用ログを追加
    scrollToElement('NICKNAME');
  };

  const renderComments = (parentId = null, level = 0) => {
    return comments
      .filter(comment => comment.UPPER_COMMENT_ID === parentId)
      .sort((a, b) => (b.COMMENT_ID || 0) - (a.COMMENT_ID || 0))
      .map(comment => (
        <div key={comment.COMMENT_ID} className={`comment level-${level}`}>
          <CommentCard 
            comment={comment} 
            commentLike={() => commentLike(comment.COMMENT_ID)}
            handleReply={handleReply}
            level={level} // 変更：レベル情報を渡す
          />
          {renderComments(comment.COMMENT_ID, level + 1)}
        </div>
      ));
  };

  if (loading) return <p>Loading...</p>;
  if (!thread) return <p>Thread not found.</p>;

  return (
    <div className="thread-detail">
      <div className="navigation">
        <button className="back-button" onClick={() => navigate('/')}>←</button>
      </div>
      <div className="thread-card-wrapper">
        <ThreadCard 
          thread={thread} 
          threadLike={() => threadLike()}
          handleReply={handleReply} 
        />
      </div>
      <div className="comments-section">
        <h2>コメント</h2>
        {comments.length === 0 ? (
          <div className="no-comments">
            <img src={Guide2} alt="No Comments" className="no-comments-image" />
            <p>「コメントを募集中です」<br />あなたの知識や経験をシェアしてみませんか・・？<br />介護を続けられるように支え合っていきましょう♪</p>
          </div>
        ) : (
          renderComments()
        )}
      </div>
      <CommentForm
        onSubmit={(TEXT, NICKNAME, PHOTO_NAME) => handleCommentSubmit(TEXT, NICKNAME, PHOTO_NAME)}
        onCancel={() => setReplyTo(null)}
        replyingTo={replyTo}
      />
    </div>
  );
};

export default ThreadDetail;