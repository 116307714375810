import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import DOMPurify from 'dompurify'; // DOMPurifyをインポート
import { Icon1, Icon2, Icon3 } from '../styles/icons';
import { FaLine, FaCopy } from 'react-icons/fa';
import { SiX } from 'react-icons/si';
import Congratulations from '../styles/assets/congratulations.png';

const PostFormWrapper = styled.div`
  background-color: #FFFAF0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
`;

const FormTitle = styled.h2`
  font-size: 20px;
  color: #66B88C;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 15px;
`;

const IconSelector = styled.div`
  position: relative;
  display: inline-block;
`;

const SelectedIcon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

const IconDropdown = styled.div`
  display: ${(props) => (props.open ? 'block' : 'none')};
  position: absolute;
  top: 60px;
  left: 0;
  background-color: #FFFAF0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background-color: #E8F4F1;
    }
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #66B88C;
  border-radius: 4px;
  outline: none;
  &::placeholder {
    color: #888888;
  }
  margin-bottom: 0.4rem;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid #66B88C;
  border-radius: 4px;
  outline: none;
  resize: vertical;
  min-height: 150px;
  &::placeholder {
    color: #888888;
  }
`;

const SelectWrapper = styled.div`
  margin-bottom: 15px;
`;

const CategoryLabel = styled.label`
  font-size: 16px;
  color: #66B88C;
  margin-bottom: 8px;
  display: block;
`;

const CategoryOption = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const RadioInput = styled.input`
  display: none;
  &:checked + label {
    background-color: #66B88C;
    color: white;
  }
`;

const RadioLabel = styled.label`
  padding: 5px 15px;
  font-size: 16px;
  color: #66B88C;
  border: 1px solid #66B88C;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  background-color: #66B88C;
  color: white;
  padding: 10px 32px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #7BC7A3;
  }

  &:disabled {
    background-color: #A9A9A9;
    cursor: not-allowed;
  }
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #66B88C;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const MessageContainer = styled.div`
  text-align: center;
  margin-top: 40px;
  color: #333333;
  position: relative;
`;

const Image = styled.img`
  width: 80%;
  max-width: 400px;
  display: block;
  margin: 0 auto;
`;

const LargeText = styled.p`
  font-size: 1.2rem;
  line-height: 1.0;
`;

const IconButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  transition: color 0.3s;
  color: ${({ $color }) => $color};

  &:hover {
    color: ${({ $hoverColor }) => $hoverColor};
  }
`;

const PostForm = () => {
  const navigate = useNavigate();
  const [threadId, setThreadId] = useState(null);
  const [NICKNAME, setNICKNAME] = useState('');
  const [TITLE, setTITLE] = useState('');
  const [TEXT, setTEXT] = useState('');
  const maxTextLength = 2000; // ★ 最大文字数を設定
  const minTextLength = 20;   // ★ 最小文字数を設定
  const [CATEGORY, setCATEGORY] = useState('');
  const [message, setMessage] = useState('');
  const [selectedIcon, setSelectedIcon] = useState(Icon1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [shareMessage, setShareMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleCategoryChange = (e) => {
    setCATEGORY(e.target.value);
    document.getElementById("CATEGORY_ERROR").textContent = ""; // カテゴリ選択時にエラーメッセージをクリア
  };

  const handleTitleChange = (e) => {
    const titleValue = e.target.value;

    if (titleValue.length > 18) {
      document.getElementById("TITLE").setCustomValidity("タイトルは18文字以内で入力してください");
      document.getElementById("TITLE").reportValidity();
    } else {
      document.getElementById("TITLE").setCustomValidity(""); // エラーがなければメッセージをクリア
    }

    setTITLE(titleValue);
  };

  const handleNicknameChange = (e) => {
    const nicknameValue = e.target.value;

    if (nicknameValue.length > 12) {
      document.getElementById("NICKNAME").setCustomValidity("ニックネームは12文字以内で入力してください");
      document.getElementById("NICKNAME").reportValidity();
    } else {
      document.getElementById("NICKNAME").setCustomValidity(""); // エラーがなければメッセージをクリア
    }

    setNICKNAME(nicknameValue);
  };

  const handleTextChange = (e) => {
    const inputText = e.target.value;
    setTEXT(inputText);
  
    // エラーメッセージをクリア
    const textArea = e.target;
    if (textArea.validity.customError) {
      textArea.setCustomValidity("");
    }
  };   

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (isSubmitting) return;
    setIsSubmitting(true);
  
    const sanitizedTitle = DOMPurify.sanitize(TITLE.trim().replace(/\s+/g, ' '));
    const sanitizedText = DOMPurify.sanitize(TEXT.trim().replace(/\s+/g, ' '));
    const nicknameToUse = NICKNAME.trim() === '' ? '名無し' : NICKNAME;
  
    // タイトルのバリデーション
    if (!sanitizedTitle) {
      document.getElementById("TITLE").setCustomValidity("タイトルを入力してください");
      document.getElementById("TITLE").reportValidity();
      setIsSubmitting(false);
      return;
    } else if (sanitizedTitle.length > 18) {
      document.getElementById("TITLE").setCustomValidity("タイトルは18文字以内で入力してください");
      document.getElementById("TITLE").reportValidity();
      setIsSubmitting(false);
      return;
    } else {
      document.getElementById("TITLE").setCustomValidity("");
    }
  
    // カテゴリのバリデーション
    const selectedCategory = document.querySelector('input[name="CATEGORY"]:checked');
    if (!selectedCategory) {
      document.getElementById("CATEGORY_ERROR").textContent = "カテゴリーを1つ選択してください";
      setIsSubmitting(false);
      return;
    } else {
      document.getElementById("CATEGORY_ERROR").textContent = "";
    }
  
    // 本文の文字数バリデーション
    const textLength = sanitizedText.trim().length;
    const textArea = document.getElementById("TEXT");
    
    if (textLength < minTextLength) {
      textArea.setCustomValidity(`本文は${minTextLength}文字以上で入力してください`);
      textArea.reportValidity();
      setIsSubmitting(false);
      return;
    } else if (textLength > maxTextLength) {
      textArea.setCustomValidity(`本文は最大${maxTextLength}文字までです`);
      textArea.reportValidity();
      setIsSubmitting(false);
      return;
    } else {
      textArea.setCustomValidity(""); // エラーメッセージをクリア
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/threads`, 
        { TITLE: sanitizedTitle, TEXT: sanitizedText, CATEGORY: selectedCategory.value, NICKNAME: nicknameToUse, PHOTO_NAME: selectedIcon },
        { withCredentials: true }
      );
      const { THREAD_ID } = response.data;
      if (THREAD_ID) {
        setThreadId(THREAD_ID);
        setNICKNAME('');
        setTITLE('');
        setTEXT('');
        setCATEGORY('');
        setSelectedIcon(Icon1);
        setShareMessage(`介護のお悩み相談所で「${sanitizedTitle}」について相談しました！無料・匿名・登録不要で介護の悩みを相談できる掲示板です。\n\n#ラクカイゴ のAmazonギフト券10,000円分が抽選で当たるキャンペーンに参加する🎁\n${window.location.origin}/`);
        setMessage(`トピックが作成されました。`);
      } else {
        setMessage('トピック作成に失敗しました');
      }
      
    } catch (error) {
      console.error('スレッドの作成に失敗しました:', error);
      setMessage('トピック作成に失敗しました');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleIconClick = (icon) => {
    setSelectedIcon(icon);
    setIsDropdownOpen(false);
  };

  const handleLineShare = () => {
    const lineUrl = `https://line.me/R/msg/text/?${encodeURIComponent(shareMessage)}`;
    window.open(lineUrl, '_blank');
  };

  const handleTwitterShare = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareMessage)}`;
    window.open(twitterUrl, '_blank');
  };

  const handleCopyLink = () => {
    if (threadId) {
      const threadUrl = `${window.location.origin}/thread/${threadId}`;
      navigator.clipboard.writeText(threadUrl).then(() => {
        alert('URLをコピーしました！');
      });
    } else {
      alert('URLの取得に失敗しました。');
    }
  };

  return (
    <PostFormWrapper>
      <div className="navigation">
        <BackButton onClick={() => navigate('/')}>←</BackButton>
      </div>
      <FormTitle>あなたの悩みを相談しましょう</FormTitle>
      <form onSubmit={handleSubmit}>
        <InputWrapper>
          <IconSelector ref={dropdownRef}>
            <SelectedIcon onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <img src={selectedIcon} alt="Selected Icon" />
            </SelectedIcon>
            <IconDropdown open={isDropdownOpen}>
              <img src={Icon1} alt="Icon 1" onClick={() => handleIconClick(Icon1)} />
              <img src={Icon2} alt="Icon 2" onClick={() => handleIconClick(Icon2)} />
              <img src={Icon3} alt="Icon 3" onClick={() => handleIconClick(Icon3)} />
            </IconDropdown>
          </IconSelector>
          <Input
            type="text"
            id="NICKNAME"
            name="NICKNAME"
            placeholder="ニックネーム（任意）"
            value={NICKNAME}
            onChange={handleNicknameChange}
            autoComplete="off"
          />
        </InputWrapper>
        <Input
          type="text"
          id="TITLE"
          name="TITLE"
          placeholder="タイトルを入力してください"
          value={TITLE}
          onChange={handleTitleChange}
          required
        />
        <Textarea
          id="TEXT"
          name="TEXT"
          placeholder="相談したいことを詳しく書いてください"
          value={TEXT}
          onChange={handleTextChange}
          required
        />
        <SelectWrapper>
          <CategoryLabel>カテゴリー選択</CategoryLabel>
          <CategoryOption>
            <RadioInput
              type="radio"
              id="category1"
              name="CATEGORY"
              value="介護制度"
              checked={CATEGORY === '介護制度'}
              onChange={handleCategoryChange}
            />
            <RadioLabel htmlFor="category1">介護制度</RadioLabel>
            <RadioInput
              type="radio"
              id="category2"
              name="CATEGORY"
              value="介護の仕方"
              checked={CATEGORY === '介護の仕方'}
              onChange={handleCategoryChange}
            />
            <RadioLabel htmlFor="category2">介護の仕方</RadioLabel>
            <RadioInput
              type="radio"
              id="category3"
              name="CATEGORY"
              value="病気の手当て"
              checked={CATEGORY === '病気の手当て'}
              onChange={handleCategoryChange}
            />
            <RadioLabel htmlFor="category3">病気の手当て</RadioLabel>
            <RadioInput
              type="radio"
              id="category4"
              name="CATEGORY"
              value="介護疲れ"
              checked={CATEGORY === '介護疲れ'}
              onChange={handleCategoryChange}
            />
            <RadioLabel htmlFor="category4">介護疲れ</RadioLabel>
            <RadioInput
              type="radio"
              id="category5"
              name="CATEGORY"
              value="仕事との両立"
              checked={CATEGORY === '仕事との両立'}
              onChange={handleCategoryChange}
            />
            <RadioLabel htmlFor="category5">仕事との両立</RadioLabel>
            <RadioInput
              type="radio"
              id="category6"
              name="CATEGORY"
              value="お金"
              checked={CATEGORY === 'お金'}
              onChange={handleCategoryChange}
            />
            <RadioLabel htmlFor="category6">お金</RadioLabel>
            <RadioInput
              type="radio"
              id="category7"
              name="CATEGORY"
              value="介護施設"
              checked={CATEGORY === '介護施設'}
              onChange={handleCategoryChange}
            />
            <RadioLabel htmlFor="category7">介護施設</RadioLabel>
            <RadioInput
              type="radio"
              id="category8"
              name="CATEGORY"
              value="便利アイテム"
              checked={CATEGORY === '便利アイテム'}
              onChange={handleCategoryChange}
            />
            <RadioLabel htmlFor="category8">便利アイテム</RadioLabel>
            <RadioInput
              type="radio"
              id="category9"
              name="CATEGORY"
              value="その他" // 「その他」カテゴリの追加
              checked={CATEGORY === 'その他'}
              onChange={handleCategoryChange}
            />
            <RadioLabel htmlFor="category9">その他</RadioLabel>
          </CategoryOption>
          <span id="CATEGORY_ERROR" style={{ color: '#FF8C00', fontSize: '0.9rem' }}></span>
        </SelectWrapper>
        <ButtonGroup>
          <Button type="submit" disabled={isSubmitting}>投稿する</Button>
        </ButtonGroup>
        <p className="comment-note">
          トピックを投稿すると、<a href={`${window.location.origin}/guideline`} className="link-button" target="_blank" rel="noopener noreferrer">ガイドライン</a>に同意したものとみなされます。
        </p>
      </form>
      {message && (
        <MessageContainer>
          <p>トピックが投稿されました</p>
          <Image src={Congratulations} alt="Congratulations" />
          <LargeText>投稿おめでとうございます！</LargeText>
          <LargeText>投稿を共有してさらに多くの人に届けましょう！</LargeText>
          <IconButtonGroup>
            <IconButton onClick={handleLineShare} $color="#00C300" $hoverColor="#00A300">
              <FaLine />
            </IconButton>
            <IconButton onClick={handleTwitterShare} $color="#000000" $hoverColor="#666666">
              <SiX />
            </IconButton>
            <IconButton onClick={handleCopyLink} $color="#333333" $hoverColor="#999999">
              <FaCopy />
            </IconButton>
          </IconButtonGroup>
        </MessageContainer>
      )}
    </PostFormWrapper>
  );
};

export default PostForm;