import React from 'react';
import './CommentCard.css';
import { Heart, MessageSquare, Flag } from 'lucide-react';

const CommentCard = ({ comment, commentLike, handleReply, level }) => {
  const handleReportClick = (postTime) => {
    const formattedTime = new Date(postTime).toLocaleString();
    const baseUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSei_CVr8nOROS5ZzdwTumXa8BY991ez7EetHHxMAZ2affyubQ/viewform';
    const params = `?usp=pp_url&entry.2022101504=${encodeURIComponent(formattedTime)}&entry.1892230761=${encodeURIComponent('攻撃的な内容（誹謗中傷、差別的な発言など）')}`;
    const reportLink = `${baseUrl}${params}`;
    window.open(reportLink, '_blank');
  };

  return (
    <div id={comment.COMMENT_ID} className="comment-card">
      <div className="comment-header">
        <div className="comment-info">
          <img src={comment.PHOTO_NAME} alt="avatar" className="comment-icon" />
          <div className="comment-meta">
            <span className="comment-author">{comment.NICKNAME}</span>
            <span className="comment-date">{new Date(comment.CREATE_DATE).toLocaleString()}</span>
          </div>
        </div>
        <Flag className="comment-report-flag" onClick={() => handleReportClick(comment.CREATE_DATE)} />
      </div>
      <div className="comment-content">
        <p>{comment.TEXT}</p>
      </div>
      <div className="comment-actions">
        <button className="action-button" onClick={commentLike}>
          <Heart /> {comment.likes || 0}
        </button>
        {/* レベルが0の場合のみ返信ボタンを表示 */}
        {level === 0 && (
          <button className={`action-button scroll-button-${comment.COMMENT_ID}`} onClick={() => handleReply(comment)}>
            <MessageSquare />
          </button>
        )}
      </div>
    </div>
  );
};

export default CommentCard;