import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flag, Heart, MessageSquare } from 'lucide-react'; // HeartとMessageSquareをインポート
import './ThreadCard.css';

const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = (`0${d.getMonth() + 1}`).slice(-2);
  const day = (`0${d.getDate()}`).slice(-2);
  const hours = (`0${d.getHours()}`).slice(-2);
  const minutes = (`0${d.getMinutes()}`).slice(-2);
  const seconds = (`0${d.getSeconds()}`).slice(-2);
  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

const generateReportLink = (postTime) => {
  const formattedTime = formatDate(postTime);
  const baseUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSei_CVr8nOROS5ZzdwTumXa8BY991ez7EetHHxMAZ2affyubQ/viewform';
  const params = `?usp=pp_url&entry.2022101504=${encodeURIComponent(formattedTime)}&entry.1892230761=${encodeURIComponent('攻撃的な内容（誹謗中傷、差別的な発言など）')}`;
  return `${baseUrl}${params}`;
};

const ThreadCard = ({ thread, threadLike, handleReply }) => {
  const navigate = useNavigate();
  const handleReportClick = (postTime) => {
    const reportLink = generateReportLink(postTime);
    console.log(reportLink);
    window.open(reportLink, '_blank');
  };

  return (
    <div className="thread-card">
      <div className="thread-header">
        <div className="thread-info" onClick={() => navigate(`/thread/${thread.THREAD_ID}`)}>
          <img src={thread.PHOTO_NAME} alt="icon" width="50" height="50" />
          <div>
            <span className="thread-author">{thread.NICKNAME}</span>
            <span className="thread-date">{new Date(thread.CREATE_DATE).toLocaleString()}</span>
          </div>
        </div>
        <Flag className="thread-report-flag" onClick={() => handleReportClick(thread.CREATE_DATE)} />
      </div>
      <div className="thread-content" onClick={() => navigate(`/thread/${thread.THREAD_ID}`)}>
        <h3 className="thread-title">{thread.TITLE}</h3>
        <p>{thread.TEXT}</p>
      </div>
      <div className="thread-actions">
        <button className="action-button" onClick={threadLike}>
          <Heart /> いいね！ {thread.likes || 0}
        </button>
        <button className="action-button" onClick={() => handleReply(thread)}>
          <MessageSquare /> コメント
        </button>
      </div>
    </div>
  );
};

export default ThreadCard;